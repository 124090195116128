import {createStore, select, setProp, setProps, withProps} from '@ngneat/elf';
import {
  createRequestsStatusOperator, selectIsRequestPending,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus
} from '@ngneat/elf-requests';
import {Injectable} from '@angular/core';
import {User as FirebaseUser, UserCredential} from '@angular/fire/auth';
import {filter, map, switchMap} from 'rxjs/operators';
import {from, Observable, pairwise} from 'rxjs';
import {Profile} from '../profile/profile-repository.service';
import {AnalyticsService} from '../analytics.service';

export interface AuthProps {
  loggedIn: boolean | null;
  firebaseUser: FirebaseUser | null | undefined;
  chatResponses: {
    profile_patch: Partial<Profile>;
    assume_desire_ids: string[];
    apply_for_user_desire_id?: string;
  }|null;
  signInProvider: string|null;
}

const authStore = createStore(
  { name: 'auth' },
  withProps<AuthProps>({
    loggedIn: null,
    firebaseUser: undefined,
    chatResponses: {
      profile_patch: {},
      assume_desire_ids: [],
      apply_for_user_desire_id: undefined
    },
    signInProvider: null,
  }),
  withRequestsStatus<'login'>()
);

export const trackLoginRequestsStatus = createRequestsStatusOperator(authStore);

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  firebaseUser$ = authStore
    .pipe(
      select(state => state.firebaseUser)
    );

  isAnonymous$ = authStore
    .pipe(
      select(state => state.firebaseUser ? state.firebaseUser.isAnonymous : null)
    );

  isLoggedIn$ = authStore
    .pipe(
      select(state => state.loggedIn),
      filter(loggedIn => loggedIn !== null)
    ) as Observable<boolean>;

  token$ = this.firebaseUser$
    .pipe(
      filter(Boolean),
      switchMap(user => from(user.getIdToken())),
    );

  afterLogin$: Observable<void> = this.isLoggedIn$
    .pipe(
      pairwise(),
      filter(([loggedBefore, loggedAfter]) => Boolean(!loggedBefore && loggedAfter)),
      map(() => {})
    );

  anonBecameRegistered$: Observable<void> = this.isAnonymous$
    .pipe(
      pairwise(),
      filter(([anonBefore, anonAfter]) => Boolean(anonBefore && !anonAfter)),
      map(() => {})
    );

  signInProvider$ = authStore.pipe(select(state => state.signInProvider));

  loginRequestStatus$ = authStore.pipe(selectRequestStatus('login'));
  isLoading$ = authStore.pipe(selectIsRequestPending('login'));

  constructor(private anal: AnalyticsService) {

  }

  setFirebaseUser(user: FirebaseUser|null) {
    authStore.update(setProps({firebaseUser: user}));
    this.anal.setUser(user ? user.uid : null);
  }

  setSignInProvider(signInProvider: string|null) {
    authStore.update(setProps({signInProvider}));
  }

  setLoggedIn(loggedIn: boolean) {
    const props: Partial<AuthProps> = {loggedIn}
    authStore.update(
      setProps(props),
      updateRequestStatus('login', 'success')
    );
  }
  isLoggedIn() {
    return authStore.getValue().loggedIn;
  }

  getFirebaseUser() {
    return authStore.getValue().firebaseUser;
  }

  setChatResponses(chatResponses: AuthProps['chatResponses']|null) {
    authStore.update(setProp('chatResponses', chatResponses));
  }
  getChatResponses() {
    return authStore.getValue().chatResponses;
  }
  get hasChatResponses() {
    return Object.keys(this.getChatResponses()?.profile_patch || {}).length > 0;
  }

  setStatusPending() {
    authStore.update(updateRequestStatus('login', 'pending'));
  }
  setStatusError(error: any) {
    authStore.update(updateRequestStatus('login', 'error', error));
  }

  clearLoginResponseStatus() {
    authStore.update(updateRequestStatus('login', 'idle'));
  }
}
