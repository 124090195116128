<div class="inner w-100 d-flex flex-column align-items-center">
  <app-lottie [config]="{
    renderer: 'canvas',
    loop: true,
    autoplay: true,
    path: '/assets/animation/lisa_404.json'
  }"></app-lottie>
  <h2 appLocalize="404_message"></h2>
  <a routerLink="/" class="btn-blue">
    <span class="text" appLocalize="404_back_to_main_page"></span>
  </a>
</div>
