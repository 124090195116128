import {
  AfterContentInit,
  AfterViewInit, ContentChild,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input, Renderer2,
  TemplateRef,
  ViewContainerRef,
  ViewRef
} from '@angular/core';
import {LocalizationService} from './localization.service';
import {ParameterTemplateDirective} from './parameter-template.directive';

interface LocalizeDirectiveParams {
  string: string;
  param: string|{[count: string]: string};
}

type LocalizeArg = LocalizeDirectiveParams | string;

@Directive({
  selector: '[appLocalize]'
})
export class LocalizeFormatDirective implements LocalizeDirectiveParams, AfterViewInit, AfterContentInit {
  @Input('appLocalize')
  set params(val: LocalizeArg) {
    if (typeof val === 'string') {
      this.string = val;
    } else {
      this.param = val.param as string;
      this.string = val.string;
    }
    this.applyView();
  }

  view?: EmbeddedViewRef<{param: string}>;

  param!: string;
  string!: string;

  @ContentChild(ParameterTemplateDirective, {read: TemplateRef})
  template!: TemplateRef<any>;

  constructor(private elementRef: ElementRef<HTMLElement>,
              private l: LocalizationService) {
  }

  ngAfterViewInit() {
    this.applyView();
  }

  ngAfterContentInit() {
    this.applyContent()
  }

  private applyView() {
    if (!this.elementRef) {
      return;
    }

    let html: string;
    if (this.template) {
      const paramHtml = this.view!.rootNodes
        .reduce((agg, el: HTMLElement|Text) =>
            agg + ((el as HTMLElement).outerHTML || (el as Text).textContent),
          '');
      html = this.l.format(this.string, paramHtml);
    } else {
      html = this.param ? this.l.format(this.string, this.param) : this.l.s[this.string] as string;
    }
    this.elementRef.nativeElement.innerHTML = html;
  }

  private applyContent() {
    if (this.template) {
      if (this.view) {
        this.view.destroy();
      }
      this.view = this.template.createEmbeddedView({param: this.param});
      this.view.detectChanges();
    }
  }
}
