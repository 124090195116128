import { Injectable } from '@angular/core';
import {LisaApiService} from '../lisa-api.service';
import {Profile, ProfileRepositoryService, trackRequestsStatus} from './profile-repository.service';
import {tap} from 'rxjs';
import {Language} from '../../_modules/localization/localization.service';
import {UserContact} from '../../desire/types';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(private api: LisaApiService,
              private repo: ProfileRepositoryService) { }

  requestProfile() {
    return this.api
      .request<Profile>('GET', '/user/profile')
  }

  initializeProfile() {
    return this.requestProfile()
      .pipe(
        tap(profile => this.repo.updateProfile(profile))
      );
  }

  fetch() {
    return this
      .requestProfile()
      .pipe(
        trackRequestsStatus('profile-fetch'),
        tap({
          next: profile => this.repo.updateProfile(profile),
          error: err => this.api.apiError('Error occured', err)
        })
      )
  }

  updateProfile(data: Partial<Profile>) {
    this.api
      .request<Profile>('PATCH', '/user/profile', {
        body: data
      })
      .pipe(trackRequestsStatus('profile-update'))
      .subscribe({
        next: profile => this.repo.updateProfile(profile),
        error: err => this.api.apiError('Error occured', err)
      });
  }

  getUserContacts() {
    return this.api
      .request<UserContact[]>('GET', '/user/profile/contact')
      .pipe(
        trackRequestsStatus('user-contacts-fetch'),
        tap({
          next: contacts => this.repo.setUserContacts(contacts),
          error: err => this.api.apiError('Error occured', err)
        })
      );
  }
  createUserContact(contact: Omit<UserContact, 'id'>) {
    return this.api
      .request<UserContact>('POST', `/user/profile/contact`, {body: contact})
      .pipe(
        tap({
          error: err => this.api.apiError('Error occured', err)
        })
      );
  }
  updateUserContact(contact: UserContact) {
    return this.api
      .request<UserContact>('PATCH', `/user/profile/contact/${contact.id}`, {body: contact})
      .pipe(
        tap({
          error: err => this.api.apiError('Error occured', err)
        })
      );
  }
  deleteUserContact(contact: UserContact) {
    return this.api
      .request<UserContact>('DELETE', `/user/profile/contact/${contact.id}`, {body: contact})
      .pipe(
        tap({
          error: err => this.api.apiError('Error occured', err)
        })
      );
  }
}
