import { Injectable } from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {ProfileRepositoryService} from './profile/profile-repository.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate: CanActivate['canActivate'] = () => {
    const redirect = sessionStorage.getItem('redirect');
    if (redirect) {
      sessionStorage.removeItem('redirect');
      return this.router.parseUrl(redirect);
    }
    return true;
  }

}
