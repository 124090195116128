import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-page-offline',
  templateUrl: './page-offline.component.html',
  styleUrls: ['./page-offline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageOfflineComponent {
}
