import {Injectable} from '@angular/core';
import {from, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {registerLocaleData} from '@angular/common';
import ruLocal from '@angular/common/locales/ru';
import enLocale from '@angular/common/locales/en';
import {ProfileRepositoryService} from '../../_misc/profile/profile-repository.service';

const locales = {
  ru: ruLocal,
  en: enLocale
}

export type Localization = Record<string, string|{[count: string]: string}>;

export type Language = 'ru'|'en';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  language: Language = this.getPersistedLanguage();
  s: Localization = {};

  pluralRules!: Intl.PluralRules;

  constructor(private profile: ProfileRepositoryService) {
  }

  str(s: string) {
    return this.s[s] as string;
  }

  setLanguage(lang: Language) {
    this.language = lang;
    return this.applyLanguage().pipe(map(() => lang));
  }

  applyLanguage() {
    registerLocaleData(locales[this.language]);
    return from(import(`../../../assets/localization/${this.language}.json`))
      .pipe(
        tap(s => {
          this.s = s;
          this.convertStrings(s);
          this.pluralRules = new Intl.PluralRules(s['_locale']);
          this.persistLanguage(this.language);
          this.profile.updateProfile({default_language: this.language});
        })
      );
  }

  format(s: string, ...args: string[]) {
    const localized = this.s[s] as string;
    return args.reduce((agg, arg) => agg.replace(/%s/, arg), localized);
  }

  getBrowserLanguage() {
    const language = navigator.language.split('-')[0] as Language;
    return locales[language] ? language : 'en';
  }

  get locale() {
    return this.s['_locale'] as string;
  }

  private persistLanguage(language: Language) {
    localStorage.setItem('locale', language);
  }

  getPersistedLanguage() {
    let language = localStorage.getItem('locale') as Language;
    if (!language) {
      language = this.getBrowserLanguage();
      this.persistLanguage(language);
    }
    return language;
  }
  private convertStrings(s: Localization) {
    Object.entries(s)
      .forEach(([key, val]) => {
        // TODO \n => <br>
      })
  }
}
