import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationSnackbarComponent} from './notification-snackbar/notification-snackbar.component';
import {ErrorSnackbarComponent} from './error-snackbar/error-snackbar.component';

@NgModule({
  declarations: [
    NotificationSnackbarComponent,
    ErrorSnackbarComponent
  ],
  exports: [
    NotificationSnackbarComponent,
    ErrorSnackbarComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SnackbarModule {
}
