import {
  Injectable, Injector,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {fromEvent, interval, pairwise, Subject, tap, throttleTime, merge, takeUntil} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {ComponentType, GlobalPositionStrategy} from '@angular/cdk/overlay';
import {Dialog, DialogConfig, DialogRef} from '@angular/cdk/dialog';
import {PlatformService} from '../../_misc/platform.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private containerRef!: ViewContainerRef;

  private readonly scrollSrc = new Subject<void>();
  readonly scroll$ = this.scrollSrc.asObservable()
    .pipe(
      throttleTime(500, undefined, {leading: true, trailing: false})
    );

  constructor(private router: Router,
              private injector: Injector,
              private platform: PlatformService,
              private dialog: Dialog) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        tap(() => this.hide())
      )
      .subscribe();
  }

  show_<R = unknown, D = unknown, C = unknown>(componentOrTemplateRef: ComponentType<C> | TemplateRef<C>, config?: DialogConfig<D, DialogRef<R, C>>): DialogRef<R, C> {
    if (this.platform.isMobileScreen) {
      return this.showMini(componentOrTemplateRef, config);
    } else {
      return this.showFull(componentOrTemplateRef, config);
    }
  }

  showFull<R = unknown, D = unknown, C = unknown>(componentOrTemplateRef: ComponentType<C> | TemplateRef<C>, config?: DialogConfig<D, DialogRef<R, C>>): DialogRef<R, C> {
    const ps = new GlobalPositionStrategy();
    config = {
      width: '100%', maxWidth: '640px',
      ...config,
      positionStrategy: ps.centerHorizontally().centerVertically(),
      panelClass: 'dialog-centered',
      closeOnNavigation: true,
    }
    if (this.platform.isMobileScreen) {
      const configOverride = {height: '100%', minHeight: undefined, maxHeight: undefined, width: '100%', minWidth: undefined, maxWidth: undefined};
      Object.assign(config,configOverride);
    }
    const modalRef = this.dialog.open(componentOrTemplateRef, config);
    modalRef.overlayRef.hostElement.classList.add('modal-overlay-wrapper');
    if (!config.disableClose) {
      modalRef.outsidePointerEvents.subscribe(e => modalRef.close());
    }

    this.blurAllOnScroll(modalRef);

    return modalRef;
  }

  showMini<R = unknown, D = unknown, C = unknown>(componentOrTemplateRef: ComponentType<C> | TemplateRef<C>, config?: DialogConfig<D, DialogRef<R, C>>): DialogRef<R, C> {
    const ps = new GlobalPositionStrategy();
    config = {
      ...config,
      maxWidth: undefined, minWidth: undefined, width: '100%',
      height: undefined, minHeight: undefined, maxHeight: undefined,
      positionStrategy: ps.bottom('-20px').start(),
      panelClass: 'dialog-bottom-sided',
    }
    const modalRef = this.dialog.open(componentOrTemplateRef, config);
    modalRef.overlayRef.hostElement.classList.add('modal-overlay-wrapper');

    if (window.visualViewport) {
      this.fixedMiniPos(modalRef);
    }

    return modalRef;
  }

  hide() {
    this.dialog.closeAll();
  }

  setModalContainer(ref: ViewContainerRef) {
    this.containerRef = ref;
  }

  private blurAllOnScroll(modalRef: DialogRef<any, any>) {
    // Убираем клавиатуру при скролле на iOS чтобы не наблюдать scrollbar-hell
    const scrollContainer = modalRef.overlayRef
      .overlayElement
      .querySelector('.cdk-dialog-container')!;

    const blurAll = () => scrollContainer
      .querySelectorAll<HTMLInputElement|HTMLTextAreaElement>('input[type=text], textarea')
      .forEach(input => input.blur());

    fromEvent([scrollContainer, window], 'scroll')
      .pipe(takeUntil(modalRef.closed))
      .subscribe(blurAll);
  }

  private fixedMiniPos(modalRef: DialogRef<any, any>) {
    const {overlayElement} = modalRef.overlayRef;

    overlayElement.style.transition = 'transform 50ms ease-out';
    const applyPosition = () => overlayElement.style.transform = `translateY(-${window.innerHeight - (window.visualViewport?.height || 0)}px)`;

    const scroll$ = fromEvent(window, 'scroll')
      .pipe(debounceTime(50));

    const sizeChanged$ = interval(50)
      .pipe(
        map(() => window.visualViewport?.height),
        distinctUntilChanged(),
      );

    merge(scroll$, sizeChanged$)
      .pipe(takeUntil(modalRef.closed))
      .subscribe(() => requestAnimationFrame(applyPosition));
  }
}


