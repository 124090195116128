<div class="app-icon">
  <img src="/assets/icons/icon-182x182-monochrome.png"
       alt="">
</div>
<h5 class="confirm-title"><b>{{ 'pwa_update_confirm_dialog_title' | localize }}</b></h5>
<p class="confirm-description">{{ 'pwa_update_confirm_dialog_description' | localize }}</p>

<section class="controls">
  <button type="button"
          class="btn-blue confirm-btn w-100"
          (click)="onConfirm()"
  >{{ 'ok' | localize }}</button>
  <button type="button"
          class="btn-grey later-btn mx-auto"
          (click)="dialogRef.close()"
  >{{ 'pwa_update_confirm_dialog_later' | localize }}</button>
</section>
