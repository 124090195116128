import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {concat, delay, fromEvent, of, Subject, tap} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataPreloaderService implements OnDestroy {
  imageCache: {[url: string]: HTMLImageElement} = {};
  animationCache: {[url: string]: any} = {};

  private downloadAnimationsSrc = new Subject<string[]>();

  constructor(private http: HttpClient) {
    this.downloadAnimationsSrc
      .pipe(
        switchMap(urls => this.downloadAnimations(urls)),
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.downloadAnimationsSrc.complete();
  }

  loadImages(urls: string[]) {
    for (const url of urls) {
      if (this.imageCache[url]) {
      } else {
        const image = createImage(url);
        this.imageCache[url] = image;
        fromEvent(image, 'load')
          .subscribe()
      }
    }
  }

  loadAnimation(urls: string[]) {
    if (urls.length > 0) {
      this.downloadAnimationsSrc.next(urls);
    }
  }

  getAnimation(url: string) {
    const cached = this.animationCache[url];
    if (cached) {
      return of(cached);
    }
    return this.animationRequest(url);
  }

  private downloadAnimations(urls: string[]) {
    const get = (url: string) => {
      return this.animationCache[url]
        ? of(null)
        : this.animationRequest(url);
    }
    return concat(...urls.map(get));
  }

  private animationRequest(url: string) {
    return this.http
      .get<any>(url, {responseType: 'json'})
      .pipe(
        tap(content => this.animationCache[url] = content)
      )
  }
}

function createImage(url: string) {
  const img = new Image();
  img.src = url;
  return img;
}
