import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {PlatformService} from './_misc/platform.service';
import {ChildrenOutletContexts, NavigationEnd, Router} from '@angular/router';
import {routerAnimations} from './_misc/animations';
import {ModalService} from './_modules/modal/modal.service';
import {SwUpdate} from '@angular/service-worker';
import {NetworkDetectionService} from './_misc/network-detection.service';
import {
  interval,
  merge,
  skipUntil,
  tap,
  throttleTime,
  timer
} from 'rxjs';
import {UpdateConfirmDialogComponent} from './_components/update-confirm-dialog/update-confirm-dialog.component';
import {filter} from 'rxjs/operators';
import {ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {DocumentVisibilityService} from './_misc/document-visibility.service';
import {AppDownloadModalComponent} from './_components/app-download-modal/app-download-modal.component';
import {AnalyticsService} from './_misc/analytics.service';

@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="networkSvc.isOnline$ | async">
      <router-outlet></router-outlet>
      <ng-template #modalContainerRef></ng-template>
    </ng-container>
    <app-page-offline *ngIf="networkSvc.isOffline$ | async"></app-page-offline>
  `,
  styles: [`:host {display: block; height: 100%}`],
  animations: routerAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('modalContainerRef', {read: ViewContainerRef})
  modalContainerRef!: ViewContainerRef;

  @ViewChild('recaptchaContainer', {read: ElementRef})
  recaptchaContainer!: ElementRef<HTMLDivElement>;

  constructor(private contexts: ChildrenOutletContexts,
              private modalSvc: ModalService,
              private r: Renderer2,
              private elRef: ElementRef,
              public networkSvc: NetworkDetectionService,
              private router: Router,
              private updates: SwUpdate,
              private platform: PlatformService,
              private anal: AnalyticsService,
              private documentVisibility: DocumentVisibilityService,
              screenTracking: ScreenTrackingService, usersTracking: UserTrackingService) {
    r.addClass(elRef.nativeElement, 'device-' + (platform.isMobileDevice ? 'mobile' : 'desktop'));
    if (platform.mobilePlatform) {
      r.addClass(elRef.nativeElement, platform.mobilePlatform);
    }

    networkSvc.enterOnline$
      .pipe(
        tap(() => location.reload()),
      )
      .subscribe();

    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.info(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.info(`Current app version: ${evt.currentVersion.hash}. New app version ready for use: ${evt.latestVersion.hash}`);
          this.modalSvc.show_(UpdateConfirmDialogComponent, {
            disableClose: true,
            maxWidth: '400px'
          });
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.info(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });

    if (updates.isEnabled) {
      /* Периодическая проверка обновлений по таймеру и при изменениях видимости страницы */

      const documentVisible$ = documentVisibility.visible$
        .pipe(
          skipUntil(timer(1000 * 60 * 2)),
          filter(Boolean),
        );

      merge(
        documentVisible$,
        interval(1000 * 60 * 20)
      )
        .pipe(throttleTime(1000 * 60 * 5))
        .subscribe(() => updates.checkForUpdate());
    }

    if (platform.isMobileDevice && !localStorage.getItem('app-download-asked')) {
      setTimeout(() => {
        this.modalSvc.show_(AppDownloadModalComponent);
        localStorage.setItem('app-download-asked', '1');
      }, 10000);
    }

    router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.anal.logAFEvent('page', {url: routerEvent.url})
      }
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalSvc.setModalContainer(this.modalContainerRef);
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
