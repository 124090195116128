<div class="inner w-100 d-flex flex-column align-items-center">
  <app-lottie [config]="{
    renderer: 'canvas',
    loop: true,
    autoplay: true,
    path: '/assets/animation/no_internet.json'
  }"></app-lottie>
  <h2 appLocalize="offline_header" class="page-header"></h2>
  <p appLocalize="offline_text" class="page-text"></p>
<!--  <a routerLink="/" class="btn-blue retry-btn">-->
<!--    <span class="text" appLocalize="offline_button"></span>-->
<!--  </a>-->
</div>
