import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PluralPipe} from './plural.pipe';
import {LocalizeFormatDirective} from './localize-format.directive';
import {ParameterTemplateDirective} from './parameter-template.directive';
import {LocalizeFormatPipe} from './localize-format.pipe';

@NgModule({
  declarations: [
    PluralPipe,
    LocalizeFormatDirective,
    ParameterTemplateDirective,
    LocalizeFormatPipe
  ],
  exports: [
    PluralPipe,
    LocalizeFormatDirective,
    ParameterTemplateDirective,
    LocalizeFormatPipe
  ],
  imports: [
    CommonModule
  ]
})
export class LocalizationModule {
}
