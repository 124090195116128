import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard, PageAccess} from './_misc/auth/auth.guard';
import {Page404Component} from './page404/page404.component';
import {PageOfflineComponent} from './page-offline/page-offline.component';
import {OnboardingGuard} from './_misc/onboarding.guard';
import {RedirectGuard} from './_misc/redirect.guard';

const routes: Routes = [
  {
    path: 'lisa',
    title: 'Lisa',
    loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule),
    canActivate: [AuthGuard, OnboardingGuard, RedirectGuard],
    data: {
      access: PageAccess.Registered
    }
  },
  {
    path: 'welcome',
    title: 'Lisa',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [AuthGuard],
    data: {
      access: PageAccess.Anonymous
    }
  },
  {
    path: 'feedback',
    title: 'Lisa',
    loadChildren: () => import('./feedback-page/feedback-page.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'login',
    title: 'Lisa',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard],
    data: {
      access: PageAccess.Anonymous
    }
  },
  {
    path: 'chat',
    title: 'Lisa',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    canActivate: [OnboardingGuard]
  },
  {
    path: 's',
    loadChildren: () => import('./desire-share/desire-share.module').then(m => m.DesireShareModule)
  },
  {
    path: 'admin',
    title: 'Lisa',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      access: PageAccess.Registered
    }
  },
  {
    path: 'experts',
    loadChildren: () => import('./experts/experts.module').then(m => m.ExpertsModule),
    canActivate: [AuthGuard],
    data: {
      access: PageAccess.Registered
    }
  },
  {
    path: 'card-preview',
    loadChildren: () => import('./card-preview/card-preview.module').then(m => m.CardPreviewModule)
  },
  {
    path: 'offline',
    title: 'Lisa',
    component: PageOfflineComponent,
  },
  {
    path: '404',
    title: 'Lisa',
    component: Page404Component,
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'account-delete',
    loadChildren: () => import('./account-delete/account-delete.module').then(m => m.AccountDeleteModule)
  },
  { path: 'gpt', loadChildren: () => import('./gpt/gpt.module').then(m => m.GptModule) },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    onSameUrlNavigation: 'reload',
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
