import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LocalizationModule} from '../../_modules/localization/localization.module';
import {DialogRef} from '@angular/cdk/dialog';
import {PlatformService} from '../../_misc/platform.service';

@Component({
  selector: 'app-app-download-modal',
  standalone: true,
	imports: [CommonModule, LocalizationModule],
  templateUrl: './app-download-modal.component.html',
  styleUrls: ['./app-download-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDownloadModalComponent implements OnInit, AfterViewInit {

  constructor(public dialogRef: DialogRef<'ok' | void>,
              public elRef: ElementRef<HTMLDivElement>,
              public platform: PlatformService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.parentElement!.style.overflow = 'visible'
  }

  onClose() {
    this.dialogRef.close();
  }

  onDownload() {
    this.dialogRef.close('ok');
  }
}
