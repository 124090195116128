import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {fromEvent, startWith} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentVisibilityService {
  visible$ = fromEvent(this.document, 'visibilitychange')
    .pipe(
      map(() => this.visible),
      distinctUntilChanged(),
      startWith(this.visible)
    );

  get visible() {
    return !this.document.hidden;
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}
}
