import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthRepository, trackLoginRequestsStatus} from './auth.repository';
import {
  catchError,
  from, Observable, of, retry,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import {
  Auth,
  authState,
  signOut,
  signInAnonymously,
  AuthError,
  UserCredential,
  updateProfile,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult,
  linkWithPhoneNumber, OAuthCredential,
  signInWithCredential, linkWithCredential, OAuthProvider, User
} from '@angular/fire/auth';
import {LisaApiService} from '../lisa-api.service';
import {Router} from '@angular/router';
import {Profile, ProfileRepositoryService} from '../profile/profile-repository.service';
import {ErrorService} from '../../_modules/snackbar/error.service';
import {LocalizationService} from '../../_modules/localization/localization.service';
import {filter, map} from 'rxjs/operators';
import {emitOnce} from '@ngneat/elf';
import {AnalyticsService} from '../analytics.service';

declare var grecaptcha: any;

export interface ProfileAuthData {
  profile_patch: Partial<Profile>|null;
  assume_desire_ids: string[];
  apply_for_user_desire_id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService implements OnDestroy {
  private unsub = new Subject<void>();

  private recaptchaVerifier!: RecaptchaVerifier;

  constructor(private http: HttpClient,
              private api: LisaApiService,
              private router: Router,
              private profileRepo: ProfileRepositoryService,
              private authRepo: AuthRepository,
              private errSvc: ErrorService,
              private l: LocalizationService,
              private firebaseAuth: Auth,
              private analSvc: AnalyticsService) {
  }

  initCaptcha(elementRef: ElementRef<HTMLElement>) {
    this.recaptchaVerifier = new RecaptchaVerifier(elementRef.nativeElement, {
      size: 'invisible',
      callback: () => {}
    }, this.firebaseAuth);
  }

  initAuthState() {
    authState(this.firebaseAuth)
      .pipe(
        takeUntil(this.unsub),
        tap(user => console.log('initAuthState', user)),
        retry({delay: 2000}),
        tap(user => this.setUser(user)),
      )
      .subscribe();
  }

  private persistAuthorized(authorized: boolean) {
    localStorage.setItem('authorized', authorized ? '1' : '0');
  }

  private setUser(user: User | null) {
    this.authRepo.setFirebaseUser(user);
    if (user) {
      user
        .getIdTokenResult()
        .then(token => this.authRepo.setSignInProvider(token.signInProvider || null));
    } else {
      this.authRepo.setSignInProvider(null);
    }
  }

  requestSmsCode(phoneNumber: string, {linkAccounts}: { linkAccounts?: boolean } = {}) {
    const currentUser = this.authRepo.getFirebaseUser();

    let confirmationPromise: Promise<ConfirmationResult>;
    if (linkAccounts !== false && currentUser?.isAnonymous) {
      confirmationPromise = linkWithPhoneNumber(currentUser, phoneNumber, this.recaptchaVerifier);
    }
    else {
      confirmationPromise = signInWithPhoneNumber(this.firebaseAuth, phoneNumber, this.recaptchaVerifier);
    }

    const onError = (err: AuthError) => {
      console.error('AuthApiService requestSmsCode', err, err.customData);
      this.errSvc.show({
        errorText: `${this.l.s['authorization_error']}: ${err.message}`,
        ttl: 3000
      });
      this.recaptchaVerifier.render().then(widgetId => grecaptcha.reset(widgetId));
    }

    return from(confirmationPromise)
      .pipe(
        tap({error: onError}),
        // trackLoginRequestsStatus('login')
      )
  }

  verifySmsCode(confirmation: ConfirmationResult, code: string) {
    const onSuccess = (credentials: UserCredential) => {
      this.setUser(credentials.user);
      this.updateUserProfile(credentials.user)
    }

    const onError = (err: AuthError) => {
      console.error('AuthApiService verifySmsCode', err, err.customData);
      if (err.code === 'auth/invalid-verification-code') {
        this.errSvc.show({
          errorText: `${this.l.s['sms_signin_wrong_code']}`,
          ttl: 3000
        });
      } else {
        this.errSvc.show({
          errorText: `${this.l.s['authorization_error']}: ${err.code}`,
          ttl: 3000
        });
      }
    }

    return from(confirmation.confirm(code))
      .pipe(
        tap({next: onSuccess, error: onError}),
        trackLoginRequestsStatus('login')
      );
  }

  destroyCaptcha() {
    try {
      this.recaptchaVerifier.clear();
    } catch (e) {
    }
  }

  signInWithCredential(credential: OAuthCredential, {link}: {link: boolean}) {
    const currentUser = this.authRepo.getFirebaseUser();

    if (link && currentUser?.isAnonymous) {
      const loginPromise = linkWithCredential(currentUser, credential)
        .then(credentials => {
          const displayName = credentials.user.providerData[0]?.displayName;
          return updateProfile(currentUser, {displayName}).then(() => credentials);
        })
        .catch(err => {
          console.dir(err);
          console.log(OAuthProvider.credentialFromError(err));
          throw err;
        });

      return from(loginPromise)
        .pipe(
          trackLoginRequestsStatus('login'),
          tap(credentials => {
            this.setUser(credentials.user);
            this.updateUserProfile(credentials.user)
          })
        );
    }

    return from(signInWithCredential(this.firebaseAuth, credential))
      .pipe(
        trackLoginRequestsStatus('login'),
        tap(credentials => {
          this.updateUserProfile(credentials.user);
        }),
      );
  }

  chatSignInAnonymously() {
    const currentUser = this.authRepo.getFirebaseUser();
    if (currentUser) {
      return of(null);
    }
    return from(signInAnonymously(this.firebaseAuth)).pipe(map(() => null));
  }

  anonSignIn() {
    const currentUser = this.authRepo.getFirebaseUser();
    if (currentUser) {
      this.updateUserProfile(currentUser);
      return of(currentUser);
    } else {
      return from(signInAnonymously(this.firebaseAuth))
        .pipe(
          trackLoginRequestsStatus('login'),
          map(credentials => credentials.user),
          tap(user => this.updateUserProfile(user))
        );
    }
  }

  signOut() {
    return from(signOut(this.firebaseAuth))
      .pipe(
        tap(() => {
          this.persistAuthorized(false);
          emitOnce(() => {
            this.authRepo.setFirebaseUser(null);
            this.authRepo.setLoggedIn(false);
          });
        })
      );
  }

  profileAuth(body: Partial<ProfileAuthData>) {
    return this.api.request<Profile>('POST', '/user/profile/auth', {body})
      .pipe(
        tap({
          next: profile => {
            this.persistAuthorized(true);
            this.authRepo.setLoggedIn(true);
            this.profileRepo.updateProfile(profile)
          }
        })
      )
  }

  updateUserProfile(user: User) {
    const profile: Partial<Profile> = {};
    if (user.email) {
      profile.email = user.email;
    }
    if (user.displayName) {
      profile.name = user.displayName;
    }
    this.profileRepo.updateProfile(profile);
    const chatResponses = this.authRepo.getChatResponses();
    const isNew = !!chatResponses?.profile_patch.name;
    const body: any = {
      profile_patch: {
        ...(chatResponses?.profile_patch || {}),
        // TODO почему всегда true
        // is_onboarding_completed: true
      },
      assume_desire_ids: chatResponses?.assume_desire_ids || [],
      apply_for_user_desire_id: chatResponses?.apply_for_user_desire_id || null
    }
    this.profileAuth(body)
      .pipe(
        tap({
          next: () => this.analSvc.logEvent('authorized', {new_user: isNew}),
          error: err => this.api.apiError('Error occured', err)
        }),
        trackLoginRequestsStatus('login'),
        catchError(() => this.signOut())
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
