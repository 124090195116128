import {Injectable, OnDestroy} from '@angular/core';
import {
  from,
  Observable,
  tap,
  combineLatest,
  Subject,
  takeUntil,
  filter, switchMap, take, share
} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthRepository} from './auth/auth.repository';
import {Messaging, getToken, deleteToken, onMessage, MessagePayload} from '@angular/fire/messaging';
import {SwHelperService} from './sw-helper.service';
import {LisaApiService} from './lisa-api.service';

// const push = new Subject<any>();
// // @ts-ignore
// window._PUSH = push;

export type NotificationState = NotificationPermission | 'unsupported';

export interface PushDataBase {
  action: 'new_match'|'user_desire_rejected';
  user_desire_id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService implements OnDestroy {
  private readonly disableSrc = new Subject<void>();

  readonly message$ = new Observable<MessagePayload>(sub => {
    sub.add(onMessage(this.firebaseMessaging, sub));
    // push.subscribe(sub);
  }).pipe(tap(msg => console.log('message', msg)), share());

  get supported() {
    return 'Notification' in window;
  }

  get state(): NotificationState {
    return this.supported ? Notification.permission : 'unsupported';
  }

  constructor(private api: LisaApiService,
              private swHelper: SwHelperService,
              private firebaseMessaging: Messaging,
              private auth: AuthRepository) {
    // @ts-ignore
    window.push_test = this.test.bind(this);
  }

  ngOnDestroy() {
    this.disableSrc.next();
    this.disableSrc.complete();
  }

  enable() {
    console.log('PushNotificationsService enable');

    const userLogout$ = this.auth
      .isLoggedIn$
      .pipe(filter(isLoggedIn => !isLoggedIn));

    userLogout$
      .pipe(takeUntil(this.disableSrc))
      .subscribe(() => this.stopListening());

    return combineLatest([
      this.swHelper.registration$,
      this.auth.isLoggedIn$
    ], (worker, _) => worker)
      .pipe(
        takeUntil(this.disableSrc),
        switchMap(worker => this.startListening(worker)),
        take(1)
      );
  }

  requstPermissionAndEnable() {
    return new Observable<boolean>(subscriber => {
      if (!this.supported) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      const handlePermission = (permission: NotificationPermission) => {
        if (permission === 'granted') {
          this.enable().subscribe();
          subscriber.next(true);
        } else {
          subscriber.next(false);
        }
        subscriber.complete();
      }

      if (checkNotificationPromise()) {
        Notification
          .requestPermission()
          .then(handlePermission);
      } else {
        Notification.requestPermission(handlePermission);
      }
    })
  }

  private startListening(serviceWorkerRegistration: ServiceWorkerRegistration) {
    console.log('PushNotificationsService startListening');

    return from(
      getToken(this.firebaseMessaging, {
        serviceWorkerRegistration,
        vapidKey: environment.vapidPublicKey
      })
    ).pipe(
      tap(token => console.log('Firebase Messaging Token:', token)),
      switchMap(token => this.postKey(token))
    )
  }

  private stopListening() {
    console.log('PushNotificationsService stopListening');
    return from(
      deleteToken(this.firebaseMessaging)
    )
  }

  private postKey(token: string) {
    return this.api.request('POST', '/user/notification/token', {
      body: {
        token,
        platform: 'web'
      }
    })
  }

  test() {
    this.auth
      .firebaseUser$
      .pipe(
        filter(Boolean),
        switchMap(user =>
          this.api.request('POST', '/v0/admin/task', {
            params: {
              task_type: 'send_user_notification'
            },
            body: {
              user_uid: user.uid,
              mdata: {
                title: 'Test title',
                body: 'Test body content here',
                action: 'new_match',
                user_desire_id: '226'
              }
            }
          })
        )
      )
      .subscribe();
  }
}

function checkNotificationPromise() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
}
