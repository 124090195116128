import {Injectable} from '@angular/core';
import {fromEvent, tap, throttleTime} from 'rxjs';
import {map} from 'rxjs/operators';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Platform} from '@angular/cdk/platform';

type Orientation = 'landscape' | 'portrait';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  orientation!: Orientation;
  isPortrait!: boolean;
  isLandscape!: boolean;
  isMobileScreen!: boolean;

  readonly mobilePlatform: 'ios' | 'android' | null = null

  readonly isMobileDevice = this.cdkPlatform.IOS || this.cdkPlatform.ANDROID;
  readonly isTouchDevice = isTouchDevice();
  readonly isApple = this.cdkPlatform.IOS || /(Mac)/i.test(navigator.platform);

  readonly resize$ = fromEvent(window, 'resize')
    .pipe(
      throttleTime(100, undefined, {trailing: true, leading: true}),
      map(() => ({width: window.innerWidth, height: window.innerHeight}))
    );

  readonly orientation$ = this.breakpointObserver
    .observe('(orientation: landscape) and (min-width: 600px)')
    .pipe(
      map(({matches}) => matches ? 'landscape' : 'portrait')
    );

  readonly isMobileScreen$ = this.breakpointObserver
    .observe(['(max-width: 599.98px)'])
    .pipe(map(val => val.matches));

  readonly isLandscapeMobile$ = this.breakpointObserver
    .observe('(max-height: 599.98px) and (orientation: landscape)')
    .pipe(
      map(({matches}) => matches)
    )

  private readonly onWindowResize = () => {
    this.orientation = this.breakpointObserver.isMatched('(orientation: landscape)') ? 'landscape' : 'portrait';
    this.isMobileScreen = this.breakpointObserver.isMatched('(max-width: 599.98px)');
    this.isPortrait = this.orientation === 'portrait';
    this.isLandscape = this.orientation === 'landscape';
  }

  constructor(public breakpointObserver: BreakpointObserver,
              public cdkPlatform: Platform) {
    this.resize$
      .pipe(tap(this.onWindowResize))
      .subscribe();

    this.onWindowResize();

    if (this.cdkPlatform.IOS) {
      this.mobilePlatform = 'ios';
    } else if (this.cdkPlatform.ANDROID) {
      this.mobilePlatform = 'android';
    }
  }
}

export function isTouchDevice() {
  // @ts-ignore
  return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}
