<header class="modal-header" xmlns="http://www.w3.org/1999/html">
  <button type="button"
          class="modal-x-btn"
          (click)="onClose()"></button>
</header>

<article class="modal-content"
         [ngSwitch]="platform.mobilePlatform">
  <h5 class="mb-3">{{ 'app_download_text' | localize }}</h5>
  <a *ngSwitchCase="'android'"
     target="_blank"
     href="https://play.google.com/store/apps/details?id=com.lisa.career.agent"
     class="btn btn-grey">{{ 'app_download_btn' | localize }}</a>
  <a *ngSwitchCase="'ios'"
     target="_blank"
     href="https://apps.apple.com/us/app/lisa-hr/id1639125591"
     class="btn btn-grey">{{ 'app_download_btn' | localize }}</a>

  <div class="app-icon">
    <img src="/assets/icons/icon-72x72.png"
         alt="">
  </div>
</article>
