import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Page404Component {
}
