import { Injectable } from '@angular/core';
import {fromEvent, merge, Observable, pairwise} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkDetectionService {
  onlineEvent$ = fromEvent(window, 'online');
  offlineEvent$ = fromEvent(window, 'offline');

  isOnlineHot$ = new Observable<boolean>(subscriber => {
    subscriber.next(navigator.onLine);
    subscriber.complete();
  });

  isOnline$ = merge(
    this.isOnlineHot$,
    this.onlineEvent$.pipe(map(() => true)),
    this.offlineEvent$.pipe(map(() => false)),
  );

  enterOnline$ = this.isOnline$
    .pipe(
      pairwise(),
      filter(([previous, current]) => !previous && current)
    );

  isOffline$ = this.isOnline$.pipe(map(isOnline => !isOnline));

  get isOnline() {
    return navigator.onLine;
  }

  constructor() {

  }
}
