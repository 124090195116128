import {animate, group, query, state, style, transition, trigger} from '@angular/animations';

export const routerAnimations = [
  trigger('fullPageRouteFadeIn', [
    transition('* <=> *', [
      style({position: 'relative', width: '100vw', height: '100vh'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        })
      ], {optional: true}),

      query(':enter', [
        style({opacity: 0}),
        animate('.4s ease-out', style({ opacity: 1 }))
      ], {optional: true}),
    ])
  ]),

  trigger('slideIn', [
    transition('* <=> *', [
      style({position: 'relative'}),
      query(':enter, :leave', [
        style({position: 'absolute', top: 0, left: 0, right: 0, bottom: 0})
      ], {optional: true}),

      group([
        query(':enter', [
          style({transform: 'translateX(-25%)', opacity: '0'}),
          group([
            animate('.3s ease-out', style({transform: 'translateX(0)'})),
            animate('.3s ease-in', style({opacity: '1'}))
          ])
        ], {optional: true}),

        query(':leave', [
          style({transform: 'scale(1)', opacity: 1}),
          animate('.3s ease-out', style({transform: 'scale(.8)', opacity: 0}))
        ], {optional: true}),
      ])
    ])
  ]),

  trigger('slideInMobile', [
    state('show' , style({left: '0', opacity: '1'})),
    transition('* => show', [
      style({left: '0'}),
      group([
        style({opacity: '0'}),
        animate('.3s ease-out', style({opacity: '1'})),
        query(':enter', [
          style({transform: 'translateX(-25%)'}),
          animate('.3s ease-out', style({transform: 'translateX(0)'}))
        ], {optional: true})
      ])
    ]),
    transition('show => *', [
      group([
        animate('.3s ease-out', style({opacity: '0'})),
        query(':leave', [
          style({transform: 'translateX(0%)'}),
          animate('.3s ease-out', style({transform: 'translateX(-25%)'}))
        ], {optional: true})
      ])
    ])
  ]),
]

export const fadeInEnter = trigger('fadeInEnter', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('100ms', style({ opacity: 1 })),
  ])
]);
