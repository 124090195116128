import {Injectable, OnDestroy} from '@angular/core';
import {
  AnimationConfigWithPath,
  AnimationItem, AnimationSegment,
  RendererType
} from 'lottie-web';
import {
  combineLatest,
  from,
  fromEvent,
  merge, share,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';
import {DataPreloaderService} from './data-preloader.service';
import {DocumentVisibilityService} from './document-visibility.service';

export interface LottieDataMarker {tm: number, cm: string, dr: number }
export interface LottieMarker {
  time: number;
  duration: number;
  payload: {
    name: string;
  }
}
export type FullAnimationItem = AnimationItem & {
  firstFrame: number;
  markers: LottieMarker[];
  animationData: { markers: LottieDataMarker[] };
}

export interface LottieEnterFrameEvent {
  currentTime: number;
  direction: number;
  totalTime: number;
  type: 'enterFrame'
}

export interface CustomEnterMarkerEvent {
  marker: string;
  type: 'enterMarker'
}

const lottieLib$ = from(import('lottie-web').then(m => m.default)).pipe(share());

@Injectable({
  providedIn: 'root'
})
export class LottieService implements OnDestroy {
  private unsub = new Subject<void>();

  constructor(private dataPreloader: DataPreloaderService,
              private documentVisibility: DocumentVisibilityService) {
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  createLottieManager<T extends RendererType = 'svg'>(params: AnimationConfigWithPath<T>) {
    const path = params.path!;
    const _params = {...params};
    delete _params.path;

    const animationData$ = this
      .getAnimationData(path)
      .pipe(tap(animationData => patchAnimationData(animationData)));

    return combineLatest([
      lottieLib$,
      animationData$,
    ], (lottie, animationData) => {
      const animationItem = lottie.loadAnimation<T>({..._params, animationData}) as FullAnimationItem;
      return new LottieManager(animationItem);
    })
  }

  private getAnimationData(url: string) {
    return this.dataPreloader.getAnimation(url)
      .pipe(
        map(data => {
          const markers = [
            ...data.markers.map((m: LottieDataMarker) => ({...m}))
          ];
          return {...data, markers};
        })
      )
  }
}

export class LottieManager {
  private unsub = new Subject<void>();

  markerMap = new Map<string, AnimationSegment>();

  pause() {
    this.inst.pause();
  }

  constructor(public inst: FullAnimationItem) {
    this.fillMarkerMap(inst.markers);
    this.attachMarkerEvent();

    setTimeout(() => inst.resize(), 0);

    merge(
      fromEvent(window, 'visibilitychange'),
      fromEvent(window, 'resize')
    )
      .pipe(
        takeUntil(this.unsub),
        filter(() => !document.visibilityState || document.visibilityState === 'visible'),
        debounceTime(100),
        filter(() => this.inst.isLoaded)
      )
      .subscribe(() => this.inst.resize())
  }

  private attachMarkerEvent() {
    const {inst} = this;
    const timeArr = [...this.markerMap.entries()];
    const inRange = (num: number, [a, b]: [number, number]) => num >= a && num <= b;

    let handler = {
      index: 0,
      emitted: false,
      adjustIndex(time: number) {
        this.index = timeArr.findIndex(([, segment]) => inRange(time, segment));
        this.emitted = false;
      },
      emit(marker: string) {
        inst.triggerEvent('enterMarker' as any, {marker, type: 'enterMarker'} as CustomEnterMarkerEvent);
        this.emitted = true;
      }
    }

    if (timeArr.length) {
      this.inst.addEventListener<LottieEnterFrameEvent>('enterFrame', function onEnterFrame(args) {
        const currentTime = inst.firstFrame + Math.max(0, args.currentTime);
        let [marker, segment] = timeArr[handler.index] || [];
        if (!inRange(currentTime, segment)) {
          handler.adjustIndex(currentTime);
          [marker] = timeArr[handler.index];
        }
        if (handler.emitted) {
          return;
        }
        if (marker) {
          handler.emit(marker);
        }
      })
    }
  }

  findSegments(...markers: string[]): AnimationSegment[] {
    return (Array.isArray(markers) ? markers : [markers]).map(m => [...this.markerMap.get(m)!]);
  }

  gotoAndPlay(marker: string) {
    this.inst.goToAndPlay(marker);
  }

  playMarkers(markers: string | string[], force?: boolean) {
    if (!Array.isArray(markers)) {
      markers = [markers];
    }
    const segments = this.findSegments(...markers);
    // console.log('playMarkers', markers, 'segments', segments, 'markerMap', this.markerMap);
    this.playSegments(segments, force);
  }

  gotoAndStop(marker: string) {
    this.inst.firstFrame = 0; /* Помогаем лотти не тупить */
    this.inst.goToAndStop(marker);
  }

  playSegments(segments: AnimationSegment[], force?: boolean) {
    this.inst.playSegments(segments, force);
  }

  findMarkerByFrame(frame: number) {
    return this.inst.markers.find(m => m.time === frame)?.payload.name;
  }

  findMarker(marker: string) {
    return this.inst.markers.find(m => m.payload.name === marker);
  }

  getMarkersDurationMs(...markerNames: string[]) {
    return markerNames.reduce((sum, markerName) => {
      const marker = this.findMarker(markerName);
      return sum + 1000 * (marker?.duration || 0) / this.inst.frameRate;
    }, 0);
  }

  destroy() {
    this.unsub.next();
    this.unsub.complete();
    this.inst.destroy();
  }

  private fillMarkerMap(markers: LottieMarker[]) {
    markers.forEach(m => this.markerMap.set(m.payload.name, [m.time, m.time + m.duration]));
  }
}

/**
 * Добавляет букву n к числовым именам маркеров. Костыль.
 */
function patchAnimationData(animationData: any) {
  const digitsRe = /^\d+$/;
  const markers = animationData.markers as LottieDataMarker[];
  markers
    .forEach((m: LottieDataMarker, i: number) => {
      digitsRe.test(m.cm) && (m.cm = 'n' + m.cm);
      const nextMarker = markers[i + 1];
      if (nextMarker) {
        m.dr = nextMarker.tm - m.tm;
      } else {
        m.dr = m.dr + 1;
      }
    })
}
