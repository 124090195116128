import {Injectable} from '@angular/core';
import {filter, from, of, shareReplay, tap} from 'rxjs';

const sw: ServiceWorkerContainer | undefined = navigator.serviceWorker;
const registrationPromise = sw?.ready.then(async () => (await sw.getRegistration())!);

@Injectable({
  providedIn: 'root'
})
export class SwHelperService {
  registration$ = registrationPromise ? from(registrationPromise) : of(null)
    .pipe(
      filter(Boolean),
      tap(worker => console.log('SwHelperService worker ready', worker)),
      shareReplay({refCount: true, bufferSize: 1})
    )
}
