import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {AuthRepository} from './auth/auth.repository';
import {Observable, single, take} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {LocalizationService} from '../_modules/localization/localization.service';
import {ErrorService} from '../_modules/snackbar/error.service';

interface RequestOptions {
  body?: any;
  headers?: {
    [header: string]: string | string[];
  };
  params?: {
    [param: string]: string | number | boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class LisaApiService {
  readonly apiUrl = environment.lisaApiUrl;

  constructor(private https: HttpClient,
              private errorSvc: ErrorService,
              private l: LocalizationService,
              private authRepo: AuthRepository) {
  }

  request<R>(method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH',
             path: string,
             options: RequestOptions = {}): Observable<R> {
    const params: RequestOptions['params'] = {locale: this.l.language, ...options.params};
    return this.authRepo.token$
      .pipe(
        filter(token => token !== null),
        take(1),
        map(token => {
          const headers = new HttpHeaders(options.headers || {})
            .set('Authorization', 'Bearer ' + token);
          return {
            ...options,
            params,
            headers,
            responseType: 'json' as const,
            observe: 'body' as const
          };
        }),
        switchMap(newOptions => this.https.request(method, this.apiUrl + path, newOptions) as Observable<R>)
      )
  }

  requestAnon<R>(method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH',
                 path: string,
                 options: RequestOptions = {}): Observable<R> {
    return this.https.request(method, this.apiUrl + path, {
      ...options,
      responseType: 'json' as const,
      observe: 'body' as const
    }) as Observable<R>
  }

  apiError(text: string|object|undefined, err: HttpErrorResponse) {
    console.error(text, err);

    let errorText: string|undefined = undefined;

    if (typeof text === 'string' || !text) {
      errorText = text;
    } else {
      try {
        errorText = JSON.stringify(text);
      } catch (e) {}
    }

    this.errorSvc.show({
      errorText,
      ttl: 5000,
      onButtonClick: 'close',
      buttonText: 'Close'
    })
  }
}
