import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ProfileRepositoryService} from './profile/profile-repository.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {
  onboardingComplete$ = this.profileRepo.profile$
    .pipe(
      map(profile => profile.is_onboarding_completed)
    )

  constructor(private profileRepo: ProfileRepositoryService,
              private router: Router) {
  }

  canActivate: CanActivate['canActivate'] = (route, state) => {
    return this.onboardingComplete$
      .pipe(
        map(onboardingComplete => {
          const isChat = route.url[0]?.toString?.() === 'chat';
          if (isChat && onboardingComplete) {
            return this.router.parseUrl('/lisa');
          } else if (!isChat && !onboardingComplete) {
            return this.router.parseUrl('/chat');
          }
          return true;
        })
      );
  }

}
