import {Injectable, Injector} from '@angular/core';
import {Analytics, logEvent} from '@angular/fire/analytics';
import {AnalyticsCallOptions, isSupported} from '@firebase/analytics';

declare var AF: (a: string, b: string, c: any) => void;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private injector: Injector) {
  }

  async logEvent(eventName: string, eventParams?: {[key: string]: any}, options?: AnalyticsCallOptions) {
    if (await isSupported()) {
      const analytics = this.injector.get(Analytics);
      logEvent(analytics, eventName, eventParams, options);
    }
    this.logAFEvent(eventName, eventParams);
  }

  logAFEvent(eventName: string, eventParams?: {[key: string]: any}) {
    AF('pba', 'event' , {
      eventType: 'EVENT',
      eventName,
      eventValue: eventParams
    })
  }

  setUser(userId: string | null) {
    AF('pba', 'setCustomerUserId' , userId)
  }
}
