import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DialogRef} from '@angular/cdk/dialog';
import {LocalizationModule} from '../../_modules/localization/localization.module';

@Component({
  selector: 'app-update-confirm-dialog',
  templateUrl: './update-confirm-dialog.component.html',
  styleUrls: ['./update-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LocalizationModule
  ]
})
export class UpdateConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: DialogRef) {
  }

  ngOnInit(): void {
  }

  onConfirm() {
    this.dialogRef.close();
    location.reload();
  }
}
