import {LocalizationService} from './_modules/localization/localization.service';
import {AuthRepository} from './_misc/auth/auth.repository';
import {ProfileRepositoryService} from './_misc/profile/profile-repository.service';
import {AuthApiService} from './_misc/auth/auth-api.service';
import {ProfileApiService} from './_misc/profile/profile-api.service';
import {Observable, retry, take, tap} from 'rxjs';
import {User} from '@angular/fire/auth';
import {filter, switchMap} from 'rxjs/operators';
import {NetworkDetectionService} from './_misc/network-detection.service';
import {Injector} from '@angular/core';
import {isSupported as isMessagingSupported} from '@angular/fire/messaging';
import {PushNotificationsService} from './_misc/push-notifications.service';

export function initializeAppFactory(injector: Injector,
                                     l: LocalizationService,
                                     authRepo: AuthRepository,
                                     profileRepo: ProfileRepositoryService,
                                     authApi: AuthApiService,
                                     profileApi: ProfileApiService,
                                     networkSvc: NetworkDetectionService): () => Observable<unknown> {
  if (networkSvc.isOnline) {
    return () => {
      authApi.initAuthState();

      return getStoredUser()
        .pipe(
          switchMap(afterAuthState)
        );
    }
  } else {
    return () => {
      return l.setLanguage(l.getPersistedLanguage());
    }
  }

  function afterAuthState(user: User | null) {
    const persistedAuth = localStorage.getItem('authorized') === '1';
    authRepo.setLoggedIn(persistedAuth && user !== null);

    if (persistedAuth && user) {
      startNotifications();
      return profileApi
        .initializeProfile()
        .pipe(
          tap(user => console.log('initializeProfile', user)),
          retry({delay: 2000}),
          switchMap(profile => l.setLanguage(/*profile.default_language*/'en'))
        );
    } else {
      // const lang = l.getPersistedLanguage();
      return l.setLanguage('en');
    }
  }

  function getStoredUser() {
    return authRepo.firebaseUser$
      .pipe(
        // @ts-ignore
        filter((user => typeof user != 'undefined')),
        take(1)
      ) as Observable<User | null>
  }

  async function startNotifications() {
    if (!await isMessagingSupported()) {
      return;
    }
    const pushSvc = injector.get(PushNotificationsService);
    if (pushSvc.state === 'granted') {
      pushSvc
        .enable()
        .subscribe()
    }
  }
}
