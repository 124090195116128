import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmModalComponent} from './confirm-modal/confirm-modal.component';
import {DialogModule} from '@angular/cdk/dialog';

@NgModule({
  declarations: [
    ConfirmModalComponent
  ],
  exports: [
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    DialogModule
  ]
})
export class ModalModule {
}
