import { Pipe, PipeTransform } from '@angular/core';
import {LocalizationService} from './localization.service';

@Pipe({
  name: 'localize'
})
export class LocalizeFormatPipe implements PipeTransform {
  constructor(private l: LocalizationService) {
  }

  transform(value: string|null|undefined, param?: string): string {
    return param ? this.l.format(value || '', param) : this.l.str(value || '');
  }

}
