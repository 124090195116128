import {Environment} from './types';

export const environment: Environment = {
  production: true,
  useEmulators: false,
  lisaApiUrl: 'https://api.lisacorp.com',
  disableCardSubmit: false,
  allowedCards: [
    'simple',
    'skill',
    'simple_action',
    'feedback',
    'user_desire',
  ],
  alwaysShowAnonLogin: false,
  firebase: {
    projectId: 'lisa-career-agent',
    appId: '1:1009931531933:web:d16d2d220ad32cc4a98674',
    databaseURL: 'https://lisa-career-agent-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'lisa-career-agent.appspot.com',
    // locationId: 'europe-west3',
    apiKey: 'AIzaSyCd-3H1MYIhZzO5ydCtfY5aZSLfhhDMENo',
    authDomain: 'lisa-career-agent.firebaseapp.com',
    messagingSenderId: '1009931531933',
    measurementId: 'G-YJVJHSR309',
  },
  firebaseAnalyticsDebugMode: false,
  vapidPublicKey: "BPDrjFmSC8aevyR08OKAhYsM01AfOcOInoBIvkcVUxPQF2slm-3sNu-turmvhX0rHUVLM3ftOkIybhnES318fEo",
  pwaAuth: {
    googleKey: '1009931531933-as2s15d6f6roir3789edqa5tll0jll5c.apps.googleusercontent.com',
    appleKey: 'com.lisacorp.app'
  },
  cdnUrl: 'https://d15xo4zyzj9z67.cloudfront.net',
  sentryDsn: 'https://f1cb34caa49a4eb48c15e2b1a939e9ff@o4504523584634880.ingest.sentry.io/4505351643791361'
};
