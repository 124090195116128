import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthRepository} from './auth.repository';
import {map, tap} from 'rxjs/operators';

export enum PageAccess {
  Anonymous, Registered, Everyone
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authRepo: AuthRepository,
              private router: Router) {
  }

  canActivate: CanActivate['canActivate'] = (route, state) => {
    const access = route.data['access'] as PageAccess;

    return this.authRepo.isLoggedIn$
      .pipe(
        map(loggedIn => {
          if (access === PageAccess.Everyone) {
            return true;
          }

          const allowActivate = (access === PageAccess.Registered) === loggedIn;

          if (!allowActivate) {
            if (access === PageAccess.Registered) {
              console.log('AuthGuard', 'redirect to welcome');
              if (state.url.startsWith('/lisa/desires/new/desire/')) {
                sessionStorage.setItem('redirect', state.url);
              }
              return this.router.parseUrl('/login');
            }
            else {
              const redirect = route.params['redirect'] || '/lisa';
              console.log('AuthGuard', 'redirect to lisa');
              return this.router.parseUrl(redirect);
            }
          }

          return true;
        })
      );
  }
}
