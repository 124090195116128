import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {LocalizationService} from '../../localization/localization.service';

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorSnackbarComponent implements OnInit {
  errorText!: string;
  buttonText?: string;
  onButtonClick?: () => void;

  constructor(public l: LocalizationService) { }

  ngOnInit(): void {
  }
}
