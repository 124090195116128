import {Injectable, OnDestroy} from '@angular/core';
import {GlobalPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ErrorSnackbarComponent} from './error-snackbar/error-snackbar.component';
import {LocalizationService} from '../localization/localization.service';

export interface ErrorSnackbarConfig {
  errorText?: string;
  buttonText?: string;
  onButtonClick?: (() => void) | 'close';
  ttl?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements OnDestroy {
  constructor(private overlay: Overlay,
              private l: LocalizationService) { }

  ps = new GlobalPositionStrategy()
    .bottom('32px')
    .centerHorizontally();
  portal = new ComponentPortal(ErrorSnackbarComponent);

  overlayRef?: OverlayRef;
  ttlTimeoutRef?: number;

  show(cfg: ErrorSnackbarConfig) {
    this.close();

    this.overlayRef = this.overlay.create({
      maxWidth: 'min(90vw, 720px)',
      minWidth: '300px',
      positionStrategy: this.ps,
      disposeOnNavigation: true
    });
    const snackbarRef = this.overlayRef.attach(this.portal);

    snackbarRef.instance.errorText = cfg.errorText || this.l.s['error_default_message'] as string;
    snackbarRef.instance.buttonText = cfg.buttonText;

    if (cfg.onButtonClick === 'close') {
      snackbarRef.instance.onButtonClick = () => this.close();
    } else {
      snackbarRef.instance.onButtonClick = cfg.onButtonClick;
    }

    if (Number.isInteger(cfg.ttl)) {
      this.ttlTimeoutRef = window.setTimeout(() => this.close(), cfg.ttl);
    }

    return this.overlayRef;
  }

  close() {
    if (this.overlayRef) {
      clearTimeout(this.ttlTimeoutRef);
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  ngOnDestroy() {
    this.close();
  }
}
