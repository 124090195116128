import {Injectable} from '@angular/core';
import {createStore, select, setProp, withProps} from '@ngneat/elf';
import {
  createRequestsStatusOperator,
  selectIsRequestPending,
  updateRequestStatus,
  withRequestsStatus
} from '@ngneat/elf-requests';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Language} from '../../_modules/localization/localization.service';
import {UserContact} from '../../desire/types';

interface ProfileStateProps {
  profile: Profile|null;
  userContacts: UserContact[];
}

export type Profile = {
  name: string;
  email?: string;
  phone?: string;
  birthdate?: string;
  sex?: 'male'|'female';
  avatar?: string;
  pictures?: string[];
  default_language: Language;
  languages?: string[];
  is_onboarding_completed: boolean;
};

const store = createStore(
  {name: 'profile'},
  withProps<ProfileStateProps>({
    profile: null,
    userContacts: [],
  }),
  withRequestsStatus<'profile-fetch'|'profile-update'|'user-contacts-fetch'|'user-contacts-upsert'>(),
);

export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({
  providedIn: 'root'
})
export class ProfileRepositoryService {
  profile$ = store
    .pipe(
      select(state => state.profile),
      filter(Boolean)
    );

  age$ = this.selectProfileProp('birthdate')
    .pipe(
      filter(Boolean),
      map(date => this.getYearsOld(new Date(date)))
    );

  userContacts$ = store.pipe(select(state => state.userContacts));

  userContactsFetchPending$ = store.pipe(selectIsRequestPending('user-contacts-fetch'));

  updateProfile(data: Partial<Profile>) {
    store.update(setProp('profile', profile => ({...profile!, ...data})));
  }

  getProfileProp<T extends keyof Profile>(prop: T): Profile[T] | null {
    return store.getValue().profile?.[prop] || null;
  }

  selectProfileProp<T extends keyof Profile>(prop: T): Observable<NonNullable<Profile[T]> | null> {
    return store.pipe(select(state => state.profile?.[prop] ?? null));
  }

  getProfile() {
    return store.getValue().profile;
  }

  setUserContacts(contacts: UserContact[]) {
    store.update(
      updateRequestStatus('user-contacts-fetch', 'success'),
      setProp('userContacts', contacts),
    );
  }

  getContacts() {
    return store.getValue().userContacts;
  }

  private getYearsOld(dateOfBirth: Date) {
    const delta = Date.now() - dateOfBirth.valueOf();
    return new Date(delta).getFullYear() - new Date(0).getFullYear();
  }
}
