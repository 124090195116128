import {APP_INITIALIZER, ErrorHandler, Injector, NgModule, Provider} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HAMMER_LOADER, HammerModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {HomeComponent} from './home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {HammerConfig} from './_misc/hammer-config.service';
import {isTouchDevice} from './_misc/platform.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Page404Component} from './page404/page404.component';
import {LottieComponent} from './_components/lottie/lottie.component';
import {CommonModule} from '@angular/common';
import {LocalizationService} from './_modules/localization/localization.service';
import {AuthApiService} from './_misc/auth/auth-api.service';
import {ProfileApiService} from './_misc/profile/profile-api.service';
import {AuthRepository} from './_misc/auth/auth.repository';
import {ProfileRepositoryService} from './_misc/profile/profile-repository.service';
import {initializeAppFactory} from './app-initializer';
import {NgxMaskModule} from 'ngx-mask';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PageOfflineComponent} from './page-offline/page-offline.component';
import {NetworkDetectionService} from './_misc/network-detection.service';
import {LocalizationModule} from './_modules/localization/localization.module';
import {ModalModule} from './_modules/modal/modal.module';
import {SnackbarModule} from './_modules/snackbar/snackbar.module';
import {UpdateConfirmDialogComponent} from './_components/update-confirm-dialog/update-confirm-dialog.component';

import {initializeApp as initializeFirebaseApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getAnalytics, provideAnalytics, UserTrackingService, ScreenTrackingService} from '@angular/fire/analytics';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {
  AllowNotificationsModalComponent
} from './_components/allow-notifications-modal/allow-notifications-modal.component'
import {OverlayModule} from '@angular/cdk/overlay';
import * as Sentry from "@sentry/angular-ivy";

const swCfgBase64 = btoa(JSON.stringify(environment.firebase));

Sentry.init({
  dsn: environment.sentryDsn,
  beforeSend: (event, hint) => {
    const err = hint.originalException as any;
    console.log({event, hint});
    if (err?.code === 'auth/network-request-failed') {
      return null;
    }
    if (err?.message?.match?.(/ChunkLoadError/i)) {
      return null;
    }
    if (err?.message?.match?.(/Http failure/i)) {
      return null;
    }
    return event;
  }
});

const providers: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeAppFactory,
    deps: [
      Injector,
      LocalizationService,
      AuthRepository,
      ProfileRepositoryService,
      AuthApiService,
      ProfileApiService,
      NetworkDetectionService
    ],
    multi: true
  },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerConfig
  },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  ScreenTrackingService,
  UserTrackingService,
];

if (isTouchDevice()) {
  providers.push(
    {
      provide: HAMMER_LOADER,
      useValue: async () => await import('hammerjs')
    }
  )
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Page404Component,
    PageOfflineComponent,
    AllowNotificationsModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    OverlayModule,
    HammerModule,
    HttpClientModule,
    AppRoutingModule,
    LottieComponent,
    SnackbarModule,
    ModalModule,
    NgxMaskModule.forRoot(),
    ServiceWorkerModule.register('sw.js?config=' + swCfgBase64, {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LocalizationModule,
    UpdateConfirmDialogComponent,

    provideFirebaseApp(() => initializeFirebaseApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging())
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule {
}
