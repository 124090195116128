import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  Output,
  ViewChild
} from '@angular/core';
import {AnimationConfigWithPath, RendererType} from 'lottie-web';
import {LottieManager, LottieService} from '../../_misc/lottie';
import {auditTime, BehaviorSubject, ReplaySubject, switchMap, tap} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AsyncPipe, NgIf} from '@angular/common';

export type LottieComponentConfig<T extends RendererType = 'canvas'> = Omit<AnimationConfigWithPath<T>, 'container'>;

@Component({
  selector: 'app-lottie',
  templateUrl: './lottie.component.html',
  styleUrls: ['./lottie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf
  ]
})
export class LottieComponent implements AfterViewInit, OnDestroy {
  private setConfigSrc = new ReplaySubject<LottieComponentConfig<RendererType>>(1);

  private managerSrc = new BehaviorSubject<LottieManager|null>(null);
  manager$ = this.managerSrc.asObservable()
    .pipe(filter(Boolean));

  loading$ = this.managerSrc.pipe(map(v => !v));

  @Output('loaded')
  loaded$ = this.manager$.pipe(map(v => v));

  @Input()
  set config(val: LottieComponentConfig<RendererType>|null) {
    const manager = this.managerSrc.getValue();
    if (manager) {
      manager.destroy();
      this.managerSrc.next(null);
    }
    if (!val) {
      this.managerSrc.next(null);
      return;
    }
    const {autoplay = true, loop = true, renderer = 'canvas'} = val;
    this.pause = !autoplay;
    val.autoplay = false;
    this.setConfigSrc.next({autoplay, loop, renderer, ...val});
  }

  @Input()
  set pause(val: boolean|null) {
    if (val !== null) {
      const manager = this.managerSrc.getValue();
      this._paused = val;
      if (manager) manager.inst[val ? 'pause' : 'play']();
    }
  }
  private _paused = false;

  @ViewChild('container', {static: true})
  containerRef!: ElementRef<HTMLElement>;

  constructor(private lottieSvc: LottieService,
              private elRef: ElementRef<HTMLElement>) {
  }

  ngAfterViewInit() {
    const container = this.containerRef.nativeElement;
    this.setConfigSrc
      .pipe(
        switchMap(cfg => this.lottieSvc.createLottieManager({...cfg, container})),
        auditTime(0),
        tap(manager => this.setManager(manager)),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.setConfigSrc.complete();
    this.managerSrc.getValue()?.destroy?.();
  }

  getManager() {
    return this.managerSrc.getValue()!;
  }

  private setManager(manager: LottieManager) {
    this.managerSrc.next(manager);
    if (this._paused !== null) {
      manager.inst[this._paused ? 'pause' : 'play']();
    }
  }
}
